'use strict';

const factory = require('./_welcome-factory');
const common = require('../common/_index');
const message = require('../message/_messagePage');

/**
 * Process requested database information
 *
 * @param data -> form data
 * @param formId -> form ID
 * @returns {Promise<unknown>}
 */
const postData = (data, formId) => {
	let getThis = common.Connect.postAjaxCall(data, formId); // Ajax call to Identity API

	return new Promise((resolve, reject) => {
		getThis.then(response => resolve(response)).catch(err => reject(err));
	});
};

/**
 * Module listeners
 */
const pageListeners = () => {
	console.log('Welcome');

	let listening = {

		init() {
			this.checkStatus()
				.cacheDom()
				.bindEvents()
				.canvasIt()
				.initCanvas();
		},
		// Cache Dom elements
		cacheDom() {
			this.page = document;
			this.btn = this.page.getElementById('signOut');
			this.body = this.page .getElementsByTagName('body');
			this.content = this.page .getElementById('content');
			this.shell = this.page .createElement('canvas');
			this.sBox = this.page .createElement('script');
			this.iBox = this.page .querySelector('.identity-content');
			return this;
		},
		// Bind event listeners
		bindEvents() {
			this.btn.addEventListener('click', this.signOut.bind(this));
			return this;
		},
		checkStatus() {
			let foo = common.Cookie.getCookie('shmoop');

			(foo === null) ? window.location.assign('/?logIn') : null;

			return this;
		},
		// Generate new page element
		generatePage(page) {
			document.querySelector('.main-content').innerHTML = page;
		},
		sendMessage(data) {
			message.init.requestedPage = 'errorMessage';
			this.generatePage(message.init.requestedPage.htmlMarkup);
			console.log(data);
		},
		signOut() {
			console.log('Signing Out');
			const empty = {};
			const formId = 'sign-out-form';

			postData(empty, formId)
			    .then(response => {
				    console.dir(response);
				    console.log('form has submitted.');
				    common.Cookie.delCookie();
				    common.Cookie.readCookie();
				    location.reload();
			    })
			    .catch(err => {
				    console.log('form has errors.');
				    console.dir(err);
				    this.sendMessage(err);
			    });
		},
		canvasIt() {
			this.content.parentNode.insertBefore(this.shell, this.content);
			this.canvasBox = document.getElementsByTagName('canvas');
			this.canvasBox[0].setAttribute('id', 'canvas');
			this.canvasBox[0].setAttribute('class', 'position-absolute');
			this.iBox.classList.add('welcome');
			return this;
		},
		initCanvas() {
			this.sBox.setAttribute('src', '/js/cScript.js');
			this.body[0].append(this.sBox);
		}
	};

	listening.init();
};

/**
 * Assembles the requested module elements and listeners
 *
 * Return requested module elements and listeners
 *
 * @returns {{htmlMarkup: *, pageListeners: *}} -> Requested module elements and listeners
 */
const init = {
	get requestedPage() {
		return {
			htmlMarkup: factory.init('welcome'),
			pageController: [pageListeners]
		};
	}
};

module.exports = {
	generateElement: factory,
	pageListeners: pageListeners,
	init: init
};
