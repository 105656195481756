'use strict';

const factory = require('./_messagePage-factory');

/**
 * Module listeners
 */
const pageListeners = () => {
	console.log('echo');
};

/**
 * Assembles the requested module elements and listeners
 *
 * @param pageRequest -> Request from URL parameter : google, user, account, logIn, resetPassword, email
 * @returns {[string, pageListeners[]]} -> Requested module elements and listeners
 */
const init = {
	set requestedPage(data) {
		this.rPage = data;
	},
	set messageTitle(data) {
		this.title = data;
		console.log(this.title);
	},
	set messageSubject(data) {
		this.subject = data;
		console.log(this.subject);
	},
	get requestedPage() {
		return {
			htmlMarkup: factory.init(this.rPage),
			pageController: [pageListeners]
		};
	}
};

module.exports = {
	pageListeners: pageListeners,
	generateElement: factory,
	init: init
};
