'use strict';

/**
 * HTML Markup
 *
 * @type {string}
 */
const subscriptionDetail = // Subscription Detail element
	`<div class="p-4"><h2 class="mb-4">Subscription-in Details</h2>
	<div class="form-row justify-content-between">
		<div class="col-6"><p><span class="font-weight-bold">Renewal Date:</span> %renewalDate%</p></div>
		<div class="col-6">
			<p class="text-center text-sm-right">
				<a href="" class="subscription-detail-btn" data-id="renew">Renew my account</a>
			</p>
		</div>
		<div class="col-6"><p><span class="font-weight-bold">Subscription Status:</span> %subscriptionStatus%</p></div>
		<div class="col-6">
			<p class="text-center text-sm-right">
				<a href="" class="subscription-detail-btn" data-id="cancel">Cancel Subscription</a>
			</p>
		</div>
	</div>
	<div class="form-row justify-content-end mt-5">
		<div class="col-6">
			<p class="text-center text-sm-right">
				<a href="" class="subscription-detail-btn" data-id="delete">Delete my account</a>
			</p>
		</div>
	</div></div>`;

module.exports = {
	subscriptionDetail: subscriptionDetail
};
