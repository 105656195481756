'use strict';

const generateElement = require('./_signInDetails-factory');

// Get the form divider
const lineDivider = () => generateElement.Factory.requestMarkup('dividedElement');

// Get Sign In title markup
const getTitle = () => generateElement.Factory.requestMarkup('signInElementTitle');

/**
 * Build the Sign In Detail edit form elements
 *
 * @param item
 * @returns {string}
 */
const signInDetailsForm = {
	// Set value
	set itemValue(item) {
		this.item = item;
	},
	// Set button value
	get buttonName() {
		return (this.item) !== 'google' ? 'Edit' : 'Change';
	},
	// Set css class
	get cssStyle() {
		return (this.item !== 'google') ? 'btn-adjustment' : 'btn-google';
	},
	// List of requested form elements
	get requestElements() {
		return [
			[
				generateElement.Factory.requestMarkup('signInInputElement'),
				generateElement.Factory.requestMarkup('buttonElement')
			]
		];
	},
	// Map drop zone targets
	get dropZoneMap() {
		return  {
			'%form%': this.item,
			'%label-name%': this.item,
			'%label%': this.item,
			'%formId%': this.item,
			'%formType%': this.item,
			'%style%': this.cssStyle,
			'%button%': this.buttonName,
			'%accordionId-target%': this.item,
			'%accordionId-controls%': this.item,
			'%accordionId%': this.item
		};
	}
};

const init = (item) => {
	// Specify top level sign-in form
	const formRequest = true;

	signInDetailsForm.itemValue = item;
	return generateElement.Factory.generateForm(
		signInDetailsForm.requestElements, signInDetailsForm.dropZoneMap, formRequest
	);
};

module.exports = {
	Builder: signInDetailsForm,
	generateElement: generateElement,
	init: init,
	getTitle: getTitle,
	lineDivider: lineDivider
};
