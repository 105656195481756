'use strict';

const generateElement = require('./_privacyComm-factory');
const common = require('../common/_index');

/**
 * Module listeners
 */
const pageListeners = () => {
	$('.button-link').click(function(e) {
		e.preventDefault();
		console.log('clicked.' + $(this).data('id'));
	});
};

/**
 * Create Personal Data module
 *
 * @returns {[*, pageListeners]}
 */
const personalDataBuild = () => {
	// Set title of module
	const title = 'Personal Data';

	// Assemble Personal Data module
	const htmlBuild = common.assembler.assembleAccordion(
		title, generateElement.Factory.getMarkup('personaDataElements')
	);

	return [htmlBuild, pageListeners]; // Personal Data module elements and listeners
};

module.exports = {
	generateElement: generateElement,
	common: common,
	init: personalDataBuild
};
