'use strict';

const welcome = require('./welcome/_index');
const signUp = require('./signUp/_index');
const logIn = require('./logIn/_index');
const resetPwd = require('./resetPassword/_index');
const accountSetting = require('./common/_accountSetting');
const common = require('./common/_index');
const message = require('./message/_messagePage');
const create = require('./createAccount/_index');

// Set the originated request message
const caller = 'from controller';

/**
 * Contains Generator functions
 *
 * @type {{generateListeners(*): void, generatePage(*): void, initializeListeners(*): *}}
 */
const Generator = {
	/**
	 * Insert the element into the HTML markup
	 *
	 * @param page
	 */
	generatePage(page) {
		document.querySelector('.main-content').innerHTML = page;
	},
	/**
	 * Initialize listeners for requested modules
	 *
	 * @param methods
	 * @returns {*}
	 */
	initializeListeners(methods) {
		return methods();
	},
	/**
	 * Generate listeners for requested modules
	 *
	 * @param fnArray
	 */
	generateListeners(fnArray) {
		// Prepare requested listeners for use
		let requestedListeners = fnArray.reduce(acc => acc);

		// Create location to store all requested listeners
		let sortedListeners = [];

		// Scope variables
		let s;

		requestedListeners.forEach(requestItem => { // Build requested listeners list
			if (Array.isArray(requestItem)) {
				for (s in requestItem) { // Handle multiple request from module
					if (typeof requestItem[s] !== 'undefined') {
						sortedListeners.push(requestItem[s]);
					}
				}
			} else { // Handle single request from module
				sortedListeners.push(requestItem);
			}
		});

		// Send all requested listeners to be initialize
		sortedListeners.forEach(listenerRequest => this.initializeListeners(listenerRequest));
	}
};

/**
 * Contains building functions
 *
 * @type {*|Master}
 */
const identity = Object.assign(Generator, {
	domData: '',
	eventData: '',

	set dom(data) {
		this.domData = data;
	},
	set listener(data) {
		this.eventData = data;
	},
	get dom() {
		return this.domData;
	},
	get listener() {
		return this.eventData;
	},

	assembleDetails(pageRequest, data) {
		accountSetting.init(pageRequest, data).then(dat => {
			this.dom = dat.htmlMarkup;
			this.listener = [dat.pageController];
			this.buildDom();
		});
	},
	sendMessage(data) {
		message.init.requestedPage = 'errorMessage';
		this.generatePage(message.init.requestedPage.htmlMarkup);
		console.log(data);
	},
	buildDom() {
		this.generatePage(this.dom);
		this.generateListeners(this.listener);
	},
	setDom(obj) {
		this.dom = obj.init.requestedPage.htmlMarkup;
		this.listener = [obj.init.requestedPage.pageController];
	}
});

/**
 * Initialize module
 *
 */
const init = () => { // TODO Refactor code block and adapt to BE response
	const pageRequest = common.Connect.urlRequest();
	let ajaxRequest,
		obj;

	switch (pageRequest) {  // Prep requested modules for assembly
	case 'logIn':
	case 'signUp':
	case 'signIn':
		obj = (pageRequest === 'signUp') ? signUp : logIn;
		identity.setDom(obj);
		identity.buildDom();
		break;
	case 'create':
		identity.setDom(create);
		identity.buildDom();
		break;
	case 'welcome':
		identity.setDom(welcome);
		identity.buildDom();
		break;
	case 'resetPassword':
		identity.setDom(resetPwd);
		identity.buildDom();
		break;
	case 'account':
	case 'user':
	case 'google':
		// Ajax gateway
		// ajaxRequest = 'Auth/GetUserInformation';
		 ajaxRequest = 'accountInfo';
		common.Connect.getAjaxCall(ajaxRequest, caller, false)  // Request database information
			.then(response => { // Response action
				if (!isNaN(response)) { // Check for failed status code response
					throw response;
				}
				identity.assembleDetails(pageRequest, response);
			})
			.catch(err => {  // Failed response action
				identity.sendMessage(err);
			});
		break;
	default:
		identity.setDom(create);
		identity.buildDom();
		break;
	}
};

module.exports = {
	Generator: Generator,
	Builder: identity,
	common: common,
	signUp: signUp,
	logIn: logIn,
	resetPwd: resetPwd,
	accountSetting: accountSetting,
	welcome: welcome,
	init: init
};
