'use strict';

const common = require('../common/_index');
const getElement = require('./_messagePage-markup');


const builder = {
	buildPage(data) {
		let s;
		let html = getElement.messageBoard;
		let dropZoneMap = {}; // Create drop zone map
		let messageData;

		console.log(data);

		if (data === 'signIn') {
			messageData = getElement.signInMessage;
		}

		if (data === 'signUp') {
			messageData = getElement.signUpMessage;
		}

		if (data === 'errorCreate') {
			messageData = getElement.errorCreateMessage;
		}

		if (data === 'errorMessage') {
			messageData = getElement.errorMessage;
		}

		if (data === 'resetMessage') {
			messageData = getElement.resetMessage;
		}

		if (data === 'resetMessage2') {
			messageData = getElement.resetMessage2;
		}

		dropZoneMap = { // Map drop zone target
			'%title%': messageData.title,
			'%subject%': messageData.message
		};

		for (s in dropZoneMap) { // Air drop targets
			if (typeof dropZoneMap[s] !== 'undefined') {
				html = html.replace(s, dropZoneMap[s]);
			}
		}
		console.log('page is building');
		return html;
	}
};


/**
 * Assembles the requested module elements, then
 * returns requested module elements
 *
 * @returns {string} -> HTML markup
 */
const init = (data) => common.assembler.generatePageTemplate(builder.buildPage(data));

module.exports = {
	common: common,
	getElement: getElement,
	init: init
};
