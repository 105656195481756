'use strict';

/**
 * Identity welcome
 */
const welcome =
	`<div class="m-5">
		<h1 class="text-center m-2">Congratulations!</h1>
		<h2 class="text-center m-3">You have successfully validated your user account with Cognito.</h2>
		<div class="form-item text-center pt-3">
		<button id="signOut" class="btn btn-default">Sign Out</button>
		</div>
	</div>`;

module.exports = {
	welcome: welcome
};
