'use strict';

/**
 * Identity create account form
 */

const createAccountForm =
	`<div class="create-account-form p-5">
	    <h1 class="card-title text-center mb-2">Create Account</h1>
	    <form id="identity-create-account"> 
	       	%for-elements%
	       	%for-disclaimer%  
	       	%for-button%     
	    </form>
	</div>`;

const feedBack =
	`<div class="invalid-feedback %feedback%-feedback this">
		<strong>Shmoop</strong> <span>Fresh</span>
	</div>`;

const formGroup =
	`<div class="form-group">
		%element%</div>`;

const inputElement =
	`<input type="%input-type%" class="form-control" 
		id="%input-id%" placeholder="Enter %holder%">`;

const userType =
	`<select id="%input-id%" class="form-control">
        <option value="student">Student</option>
        <option value="teacher">Teacher</option>
	</select>`;

const label =
	`<label for="%label-for%">
		%label%</label>`;

const password =
	`<div class="input-group password-eye">
	        %for-input%
	        <div class="input-group-append">
	            <div class="input-group-text">
	                <i class="fas fa-eye reveal-password"></i>
	                <i class="fas fa-eye-slash cloak-password d-none"></i>
	            </div>
	        </div>
	        %for-feedback%     
	    </div>`;

const birthDate =
	`<ul class="d-flex justify-content-between pl-0">
        <li class="flex-fill mr-2">
            <select id="year" class="form-control">
                <option selected>Year</option>
                %for-year%
			</select>
		</li>
        <li class="flex-fill mx-2">
            <select id="month" class="form-control">
                <option selected>Month</option>
                %for-month%
			</select>
		</li>
        <li class="flex-fill ml-2">
            <select id="day" class="form-control">
                <option selected>Day</option>
                %for-day%
			</select>
		</li>
	</ul>`;

const disclaimer =
	`<small>By clicking &quot;Create My Account&quot;, you agree to the Terms of Use 
	        and the  Privacy Policy and to 
	        receive updates and important communications from Shmoop electronically. If you're creating a teacher 
	        account, you confirm that you are a teacher and have provided accurate information and agree that Shmoop 
	        may contact your school to verify your status.</small>`;

const buttonElement =
	`<div class="position-relative mt-4">
	        <i class="fas fa-spinner fa-spin spinning-spinner d-none"></i>
	        <button name="signUp-button" type="submit" class="btn btn-primary mx-auto w-100 sign-up-button">
		Create my account</button></div>`;

module.exports = {
	createAccountForm: createAccountForm,
	disclaimer: disclaimer,
	buttonElement: buttonElement,
	birthDate: birthDate,
	password: password,
	label: label,
	feedBack: feedBack,
	userType: userType,
	formGroup: formGroup,
	inputElement: inputElement
};
