'use strict';

const common = require('../common/_index');
const factory = require('./_resetPassword-factory');
const verify = require('../common/_verification');
const message = require('../message/_messagePage');

/**
 * Process requested database information
 *
 * @param data -> form data
 * @param formId -> form ID
 * @returns {Promise<unknown>}
 */
const postData = (data, formId) => {
	let getThis = common.Connect.postAjaxCall(data, formId); // Ajax call to Identity API

	return new Promise((resolve, reject) => {
		getThis.then(response => resolve(response)).catch(err => reject(err));
	});
};

/**
 * Module listeners
 */
const pageListeners = () => {
	let listening = {
		eventValue: '',
		targetIdValue: '',
		inputIdValue: '',
		dataBoxValue: '',
		formDataValue: '',


		set formData(data) {
			this.formDataValue = data;
		},
		get formData() {
			return this.formDataValue;
		},
		set eventType(data) {
			this.eventValue = data;
		},
		get eventType() {
			return this.eventValue;
		},
		set targetId(data) {
			this.targetIdValue = data;
		},
		get targetId() {
			return this.targetIdValue;
		},
		set inputId(data) {
			this.inputIdValue = data;
		},
		get inputId() {
			return this.inputIdValue;
		},
		set dataBox(data) {
			this.dataBoxValue = data;
		},
		get dataBox() {
			return this.dataBoxValue;
		},
		get pass() {
			return this.dataBox.response;
		},

		// Initialize listening module
		init() {
			this.cacheDom();
			this.bindEvents();
		},

		// Cache Dom elements
		cacheDom() {
			this.page = document;
			this.identityPasswordReset = this.page.getElementById('identity-password-reset');
			this.emailBox = this.page.getElementById('email');
			this.emailTitle = this.emailBox.nextElementSibling.firstElementChild;
			this.emailExplanation = this.emailTitle.nextElementSibling;
			this.submitButton = this.page.querySelector('.reset-email-button');
		},

		// Bind event listeners
		bindEvents() {
			this.identityPasswordReset.addEventListener('submit', this.initResponse.bind(this));
			this.emailBox.addEventListener('keyup', this.initResponse.bind(this));
		},

		// Unbind event listeners
		unBindEvents() {
			this.identityPasswordReset.removeEventListener('submit', this.initResponse.bind(this));
			this.emailBox.removeEventListener('keyup', this.initResponse.bind(this));
			return this;
		},

		// Render page
		pageRender() {
			const data = this.dataBox;
			const blankSpot = '';
			let inputBox,
				inputTitle,
				inputExplanation;

			// Setup request locations
			inputBox = this.emailBox;
			inputTitle = this.emailTitle;
			inputExplanation = this.emailExplanation;

			// Write data to Dom
			inputTitle.innerHTML = data.message.title || blankSpot;
			inputExplanation.innerHTML = data.message.explanation || blankSpot;

			// Add styles to Dom
			inputBox.classList.remove(...inputBox.classList);
			inputBox.classList.add('form-control', data.response.toString());
		},

		// Validate data
		validate() {
			// Set response to data module
			this.dataBox = verify.init(this.inputId, this.emailBox.value);
			return this;
		},

		// Set the input box to use for data submission
		setInputBox() {
			// Set the input value id
			this.inputId = this.targetId;
			console.log(this.inputId);
			return this;
		},

		// Form submit functions
		redirectPage(data) {
			window.location.href = data;
		},

		// Write to the DOM
		generatePage(page) {
			document.querySelector('.main-content').innerHTML = page;
		},

		elementOff() {
			// Set submit button
			const theButton = this.submitButton;

			// Current spinner from FontAwesome
			const spinner = theButton.previousElementSibling;

			// Change element states
			theButton.disabled = true;
			theButton.innerText = 'Confirming';

			// Change element styles
			theButton.classList.add('button-off');
			spinner.classList.remove('d-none');
			return this;
		},

		// Form submit functions
		sendForm() {
			// Input box in form to submit
			const inputBox = this.emailBox;
			let data = {
				'username': inputBox.value
			};

			// data[inputBox.id] = inputBox.value;

			console.dir(data);

			this.formData = this.identityPasswordReset;

			return new Promise((resolve, reject) => {
				postData(data, this.formData.id)
					.then(response => {
						resolve(response);
					})
					.catch(err => {
						reject(err);
					});
			});
		},

		// Controller
		initResponse(e) {
			let theRequest;

			// Set event type to the module
			this.eventType = e.type;

			// Set target ID / Form ID
			this.targetId = e.target.id;

			// Prevent form submission
			e.preventDefault();

			this.setInputBox()  // Set Input box
				.validate() // Validate the response data
				.pageRender(); // Render page

			// Validate
			if (this.pass && this.eventType === 'submit') {
				this.elementOff()
					.sendForm()
					.then(response => {
						theRequest = (response.userData.result) ? 'resetMessage' : 'resetMessage2';
						message.init.requestedPage = theRequest;
						this.unBindEvents()
							.generatePage(message.init.requestedPage.htmlMarkup);
					})
					.catch(() => {
						message.init.requestedPage = 'errorMessage';
						this.unBindEvents()
							.generatePage(message.init.requestedPage.htmlMarkup);
					});
			} else {
				console.log('blocked');
			}
		}
	};

	listening.init();
};

/**
 * Assembles the requested module elements and listeners
 *
 * @param pageRequest -> Request from URL parameter : google, user, account, logIn, resetPassword, email
 * @returns {[string, pageListeners[]]} -> Requested module elements and listeners
 */
const init = {
	get requestedPage() {
		return {
			htmlMarkup: factory.init(),
			pageController: [pageListeners]
		};
	}
};

module.exports = {
	pageListeners: pageListeners,
	generateElement: factory,
	init: init
};
