'use strict';

const common = require('../common/_index');
const signInDetailForm = require('./_signInDetails-form');
const signInDetailCommon = require('./_signInDetails-common');
const verify = require('../common/_verification');
const message = require('../message/_messagePage');

/**
 * Process requested database information
 *
 * @param data -> form data
 * @param formId -> form ID
 * @returns {Promise<unknown>}
 */
const postData = (data, formId, fresh) => {
	const travel = fresh ? fresh : false;
	let getThis = common.Connect.postAjaxCall(data, formId, travel); // Ajax call to Identity API

	return new Promise((resolve, reject) => {
		getThis.then(response => resolve(response)).catch(err => reject(err));
	});
};

/**
 * Module listeners
 */
const pageListeners = () => {
	let listening = {
		passwordId: 'password-detail-box',
		usernameId: 'username-detail-box',
		inputIdValue: '',
		inputValue: '',
		formDataValue: '',
		eventValue: '',
		dataBoxValue: '',
		targetIdValue: '',

		set inputVal(data) {
			this.inputValue = data;
		},
		get inputVal() {
			return this.inputValue;
		},
		set targetId(data) {
			this.targetIdValue = data;
		},
		get targetId() {
			return this.targetIdValue;
		},
		set dataBox(data) {
			this.dataBoxValue = data;
		},
		get dataBox() {
			return this.dataBoxValue;
		},
		set inputId(data) {
			this.inputIdValue = data;
		},
		get inputId() {
			return this.inputIdValue;
		},
		set eventType(data) {
			this.eventValue = data;
		},
		get eventType() {
			return this.eventValue;
		},
		set formData(data) {
			this.formDataValue = data;
		},
		get formData() {
			return this.formDataValue;
		},
		get pass() {
			return this.dataBox.response;
		},

		// Initialize listening module
		init() {
			this.cacheDom();
			this.bindEvents();
		},

		// Cache Dom elements
		cacheDom() {
			this.page = document;
			this.googleButton = this.page.querySelector('.btn-google');
			if (this.googleButton !== null) {
				this.googleBox = this.googleButton.closest('.form-group').nextElementSibling;
			}
			this.cancelButtons = this.page.querySelectorAll('.cancel-btn');
			this.getDetail = this.page.querySelectorAll('.get-detail');
			this.allForms = this.page.querySelectorAll('form');
			this.revealPassword = this.page.querySelector('.reveal-password');
			this.cloakPassword = this.page.querySelector('.cloak-password');
			this.emailDetailBox = this.page.getElementById('email-detail-box');
			this.passwordDetailBox = this.page.getElementById(this.passwordId);
			this.usernameDetailBox = this.page.getElementById(this.usernameId);
			this.emailTitle = this.emailDetailBox.nextElementSibling.firstElementChild;
			this.emailExplanation = this.emailTitle.nextElementSibling;
			this.usernameTitle = this.usernameDetailBox.nextElementSibling.firstElementChild;
			this.usernameExplanation = this.usernameTitle.nextElementSibling;
			this.passwordTitle = this.passwordDetailBox.nextElementSibling.nextElementSibling.firstElementChild;
			this.passwordExplanation = this.passwordTitle.nextElementSibling;
		},

		// Bind event listeners
		bindEvents() {
			this.passwordDetailBox.addEventListener('keyup', this.passwordLengthCheck.bind(this));
			this.usernameDetailBox.addEventListener('keyup', this.initResponse.bind(this));
			this.emailDetailBox.addEventListener('keyup', this.initResponse.bind(this));
			this.revealPassword.addEventListener('click', this.reveal.bind(this));
			this.cloakPassword.addEventListener('click', this.cloak.bind(this));
			this.getDetail.forEach(btn => {
				btn.addEventListener('click', this.hideDetailButton.bind(this));
			});

			this.cancelButtons.forEach( btn => {
				btn.addEventListener('click', this.showDetailButton.bind(this));
			});

			this.allForms.forEach(goForm => {
				goForm.addEventListener('submit', this.initResponse.bind(this));
			});

			if (this.googleButton !== null) {
				this.googleButton.addEventListener('click', this.gotoGoogle.bind(this));
			}
		},

		// Render page
		pageRender() {
			const request = this.inputId;
			const data = this.dataBox;
			const blankSpot = '';
			let inputBox,
				inputTitle,
				inputExplanation;

			// Setup request locations
			if (request === this.passwordId) {
				inputBox = this.passwordDetailBox;
				inputTitle = this.passwordTitle;
				inputExplanation = this.passwordExplanation;
			} else if (request === this.usernameId) {
				inputBox = this.usernameDetailBox;
				inputTitle = this.usernameTitle;
				inputExplanation = this.usernameExplanation;
			} else {
				inputBox = this.emailDetailBox;
				inputTitle = this.emailTitle;
				inputExplanation = this.emailExplanation;
			}

			// Write data to Dom
			inputTitle.innerHTML = data.message.title || blankSpot;
			inputExplanation.innerHTML = data.message.explanation || blankSpot;

			// Add styles to Dom
			inputBox.classList.remove(...inputBox.classList);
			inputBox.classList.add('form-control', data.response.toString());
		},

		// Validates data
		dataPrep() {
			const responseRequest = this.inputId;
			let response;

			// Determine what input box to use
			if (responseRequest === this.passwordId) {
				response = this.passwordDetailBox;
			} else if (responseRequest === this.usernameId) {
				response = this.usernameDetailBox;
			} else {
				response = this.emailDetailBox;
			}

			// Set response to data module
			this.inputVal = response.value;
			return this;
		},
		// Validate data
		validate() {
			const responseRequest = this.inputId;
			let response;

			// Determine what input box to use
			if (responseRequest === this.passwordId) {
				response = this.passwordDetailBox;
			} else if (responseRequest === this.usernameId) {
				response = this.usernameDetailBox;
			} else {
				response = this.emailDetailBox;
			}

			// Set response to data module
			this.dataBox = verify.init(responseRequest, response.value);
			return this;
		},

		// Set the input box to use for data submission
		setInputBox() {
			const target = this.targetId;
			let inputBoxes,
				lastNode;

			// Set the input box Id data in module
			if (this.eventType === 'submit') {
				// Sets the form to the module
				this.formData = this.page.getElementById(target);

				// Gathers all input box
				inputBoxes = this.formData.getElementsByTagName('input');

				// Get the last input
				lastNode = inputBoxes[inputBoxes.length - 1];

				// Set the input value id
				this.inputId = lastNode.id;
			} else {
				// Set the input value id
				this.inputId = target;
			}
			return this;
		},

		// Controller
		initResponse(e) {
			// Set event type to the module
			this.eventType = e.type;

			// Set target ID
			this.targetId = e.target.id;

			// Prevent form submission
			e.preventDefault();

			this.setInputBox()  // Set Input box
				.validate() // Validate the response data
				.pageRender(); // Render page

			// Validate
			if (this.pass && this.eventType === 'submit'   ) {
				this.proceedOn();
			} else {
				console.log('blocked');
			}
		},

		// Back to the future
		knock() {
			console.log(this.inputVal);
			console.log(this.targetId);
			postData(this.inputVal, this.targetId)
				.then(response => {
					console.dir(response);
				})
				.catch(err => {
					this.sendMessage(err);
				});
		},
		// Proceed to form submission
		proceedOn() {
			this.dataPrep()
				.knock();
		},
		// proceedOn() {
		// 	if (this.eventType === 'submit') {
		// 		console.log('cool, submitting form');
		// 		// this.initCookie();
		// 		this.sendForm();
		// 	} else {
		// 		console.log('No submission, only keyup event');
		// 	}
		// },

		// Check password length requirement
		passwordLengthCheck(e) {
			common.ToolBox.lengthCheck = this.passwordDetailBox.value;

			if (common.ToolBox.lengthCheck) {
				this.initResponse(e);
			}
		},

		// Show the .get-detail button
		showDetailButton() {
			const activeRequest = this.page.querySelectorAll('.get-detail.active-request');
			const doneButton = this.page.querySelector('.done-btn-adjustment');

			if (doneButton) {
				doneButton.innerHTML = 'Cancel';
				doneButton.classList.remove('done-btn-adjustment');
			}

			activeRequest.forEach(item => {
				item.classList.remove('active-request');
				item.classList.add('rest-request');
			});
		},

		// Hide the .get-detail button
		hideDetailButton() {
			let detailButton;

			// Hide the .get-detail button
			this.allForms.forEach(data => {
				detailButton = data.querySelector('button.get-detail');

				if (detailButton !== null) {
					detailButton.classList.add('active-request');
					detailButton.classList.remove('rest-request');
				}
			});
		},

		// Form submit functions
		sendForm() {
			// All input box in form
			const inputBox = this.formData.getElementsByTagName('input');

			// Last input box
			// const data = inputBox[Math.floor(inputBox.length - 1)].value;

			const inputElement = inputBox[Math.floor(inputBox.length - 1)];
			const elId = inputElement.id.split('-')[0];
			const elVal = inputElement.value;

			console.log(inputElement.id.split('-')[0]);
			console.log(inputElement.value);
			// All buttons in form
			const formButtons = this.formData.getElementsByTagName('button');
			const cancelButton = formButtons[Math.floor(formButtons.length / 2)];
			const changeButton = formButtons[Math.floor(formButtons.length - 1)];

			// Current spinner from FontAwesome
			const spinner = changeButton.previousElementSibling;

			let responseData;
			let inputData = {};

			// Build the data object
			inputData[elId] = elVal;
			console.dir(inputData);

			// Change element states
			cancelButton.disabled = true;
			changeButton.disabled = true;

			// Change element styles
			changeButton.classList.add('button-off');
			spinner.classList.remove('d-none');

			postData(inputData, this.formData.id)
				.then(response => {
					responseData = Object.values(response).reduce((total, value) => value);

					Array.from(inputBox).forEach( item => item.value = responseData);
					console.log('update successful!');
					// Change element states
					cancelButton.innerText = 'Done';
					cancelButton.disabled = false;
					changeButton.disabled = false;

					// Change element styles
					cancelButton.classList.add('done-btn-adjustment');
					changeButton.classList.remove('button-off');
					spinner.classList.add('d-none');
				})
				.catch(err => {
					this.sendMessage(err);
				});
		},

		sendMessage(data) {
			message.init.requestedPage = 'errorMessage';
			this.generatePage(message.init.requestedPage.htmlMarkup);
			console.log(data);
		},

		// Allows view of the password
		reveal() {
			this.cloakPassword.classList.remove('d-none');
			this.revealPassword.classList.add('d-none');
			this.passwordDetailBox.type = 'text';
			this.passwordDetailBox.focus();
		},

		// Cloaks the password
		cloak() {
			this.revealPassword.classList.remove('d-none');
			this.cloakPassword.classList.add('d-none');
			this.passwordDetailBox.type = 'password';
			this.passwordDetailBox.focus();
		},

		// Goto Google
		gotoGoogle() {
			this.googleBox.remove();
			alert('Going to GOOGLE!');
		}
	};

	listening.init();
};

/**
 * Contains building functions
 *
 * @type {{formBuilder(*, *): *[], detailBuilder(*=): *}}
 */
const signInDetail = {
	/**
	 *
	 * Build Sign In detail elements
	 *
	 * @param item -> Requested form input fields 'google, username, email, password'
	 * @returns {*} -> Requested module elements markup
	 */
	detailBuilder(item) {
		const formType = item === 'password' ? item : 'text'; // Checks if its a Password input type

		return signInDetailCommon.init(item, formType); // Requested module elements markup
	},

	/**
	 * Create Sign In Details form
	 *
	 * @param data -> Request database information
	 * @param pageRequest -> Request from URL parameter : google, user, account, logIn, resetPassword, email
	 * @returns {*[]} -> Requested module elements and listeners
	 */
	formBuilder(data, pageRequest) { // TODO @param pageRequest: needs to refactor to BE response
		// Create location to store Sign-in Details input fields
		const signInData = [];

		// Get Sign In title markup
		let buildHtml = signInDetailForm.getTitle();

		// Scope variables
		let	html,
			dropZoneMap,
			value,
			isFullAcctView,
			s,
			details,
			subValue;

		if (pageRequest === 'user') { // TODO Refactor code block and adapt to BE response
			signInData.push('username', 'email', 'password');
		} else if (pageRequest === 'google') {
			signInData.push('google');
		} else {
			signInData.push('google', 'username', 'email', 'password');
			isFullAcctView = true;
		}

		signInData.forEach( item => { // Create input fields
			switch (item) { // Sort data
			case 'google':
				value = data.google;
				break;
			case 'username':
				value = data.username;
				break;
			case 'email':
				value = data.email;
				break;
			case 'password':
				value = data.password;
				break;
			}

			dropZoneMap = {}; // Create drop zone map
			dropZoneMap = { // Map drop zone targets
				'%formValue%': value
			};

			if (item !== 'google') { // Non GOOGLE request
				details = this.detailBuilder(item);
				subValue = {
					'%details%': details,
					'%formValueOne%': value
				};
				dropZoneMap = Object.assign(dropZoneMap, subValue); // Merge both maps
			}
			html = signInDetailForm.init(item); // Get Sign In markup`

			for (s in dropZoneMap) { // Air drop targets
				if (typeof dropZoneMap[s] !== 'undefined') {
					html = html.replace(s, dropZoneMap[s]);
				}
			}
			// Add divider under Google
			html = (item === 'google' && isFullAcctView) ? html += signInDetailForm.lineDivider() : html;

			buildHtml += html; // Assemble Sign In Detail elements
		});

		return [buildHtml, pageListeners];  // Requested module elements and listeners
	}
};

/**
 * Initialize module
 *
 */
const init = (data, pageRequest) => signInDetail.formBuilder(data, pageRequest);

module.exports = {
	Builder: signInDetail,
	common: common,
	signInDetailCommon: signInDetailCommon,
	signInDetailForm: signInDetailForm,
	pageListeners: pageListeners,
	postData: postData,
	init: init
};
