'use strict';

/**
 * Builds the Form card
 *
 * @returns {string}
 */
const buildCard =
	`<div class="row">
		<div class="col-12 col-md-7 mx-auto">
			<div class="card identity-box-shadow identity-content mb-md-5 mx-auto w-100" id="identity-module">
				<div class="card-body p-0">%requestForm%</div>
			</div>
		</div>
	</div>`;

module.exports = {
	buildCard: buildCard
};
