'use strict';

const personalData = require('./_privacyComm-personalData');
const privacyCommEmailPreferences = require('./_privacyComm-emailPreferences');
const common = require('../common/_index');

const privacyCommunication = {
	// Set the module title
	title: 'Privacy &amp; Communication',

	// Set module data
	set setVal(data) {
		this.data = data;
	},
	// Get first and last words of the title
	get dubTitle() {
		return this.title.toLowerCase().match(/^\w+|\w+$/g);
	},

	// Assign the accordion name
	get accordionName() {
		return `${this.dubTitle[0]}-${this.dubTitle[1]}`;
	},

	// Get persona data
	get getPersonalData() {
		return personalData.init();
	},

	// Initialize Persona Data accordion
	get personalDataTools() {
		return this.getPersonalData[0].replace('%accordions-name-parent%', this.accordionName);
	},

	// Initialize Email Marketing Preferences accordion
	get empAccordion() {
		return this.data[0].replace('%accordions-name-parent%', this.accordionName);
	},

	// Assemble build modules
	get accordionContent() {
		return this.empAccordion + this.personalDataTools;
	},

	// Get accordion wrapper
	get htmlMarkup() {
		return common.assembler.assembleAccordionWrap(this.title, this. accordionName, this.accordionContent);
	},

	// Get page listeners
	get listeners() {
		// Create location for module listeners
		let listenerArray = [];

		listenerArray.push(this.getPersonalData[1], this.data[1]); // Package module listeners

		return [this.htmlMarkup, listenerArray]; // Return Privacy & Communication element
	}
};

/**
 * Create Privacy & Communication module
 *
 * @param data -> Request database information
 * @returns {*[]}
 */
let init = data => {
	privacyCommunication.setVal = privacyCommEmailPreferences.init(data); // Set module data
	return privacyCommunication.listeners; // Return Privacy & Communication element
};

module.exports = {
	Factory: privacyCommunication,
	privacyCommEmailPreferences: privacyCommEmailPreferences,
	init: init
};
