'use strict';

/**
 * HTML Markup
 *
 * @type {string}
 */
const resetPasswordForm = // Identity reset password form
	`<div class="reset-password-form p-5" >
		<h1 class="card-title text-center mb-4 text-greyscale-11">Reset Password</h1>
		<form id="identity-password-reset">
		  <div class="form-group">
		    <label for="email-reset" class="m-b-3 text-center">
		        Enter your email to reset your password.
		    </label>
		    <input type="email" class="form-control" id="username" placeholder="Email">
		    <div class="invalid-feedback this"><strong></strong> <span></span></div>
		  </div>
		  
		  <div class="position-relative">
	        <i class="fas fa-spinner fa-spin spinning-spinner d-none"></i>
	   <button type="submit" class="btn btn-primary mx-auto w-100 reset-email-button mt-2">Reset my password</button>
	        </div>	  
		</form>
	  </div>`;

const emailMessageBoard = // Identity email message board
	`<div class="email-message-board p-5">
		<h1 class="card-title text-center my-5">Check your email!</h1>
		<p class="my-5">If you have an account, you should receive a link to reset your password.</p>
	</div>`;

module.exports = {
	resetPasswordForm: resetPasswordForm,
	emailMessageBoard: emailMessageBoard
};
