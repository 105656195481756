'use strict';

const factory = require('./_logIn-factory');
const verify = require('../common/_verification');
const common = require('../common/_index');
const message = require('../message/_messagePage');


/**
 * Process requested database information
 *
 * @param data -> form data
 * @param formId -> form ID
 * @returns {Promise<unknown>}
 */
const postData = (data, formId) => {
	let getThis = common.Connect.postAjaxCall(data, formId); // Ajax call to Identity API

	return new Promise((resolve, reject) => {
		getThis.then(response => resolve(response)).catch(err => reject(err));
	});
};

/**
 * Module listeners
 */
const pageListeners = () => {
	let listening = {
		passwordBox: 'password',
		usernameStatus: false,
		passwordStatus: false,
		dataBoxValue: [],
		eventValue: '',
		formDataValue: '',
		targetIdValue: '',
		inputIdValue: [],

		set dataBox(data) {
			this.dataBoxValue = data;
		},
		get dataBox() {
			return this.dataBoxValue;
		},
		set inputId(data) {
			this.inputIdValue = data;
		},
		get inputId() {
			return this.inputIdValue;
		},
		set targetId(data) {
			this.targetIdValue = data;
		},
		get targetId() {
			return this.targetIdValue;
		},
		set eventType(data) {
			this.eventValue = data;
		},
		get eventType() {
			return this.eventValue;
		},
		set formData(data) {
			this.formDataValue = data;
		},
		get formData() {
			return this.formDataValue;
		},

		// Initialize listening module
		init() {
			this.cacheDom();
			this.bindEvents();
		},

		// Cache Dom elements
		cacheDom() {
			this.page = document;
			this.submitButton = this.page.querySelector('.sign-in-button');
			this.revealPassword = this.page.querySelector('.reveal-password');
			this.cloakPassword = this.page.querySelector('.cloak-password');
			this.passwordId = this.page.getElementById('password');
			this.usernameId = this.page.getElementById('username');
			this.identitySignIn = this.page.getElementById('identity-logIn');
			this.passwordTitle = this.page.querySelector('.password-feedback > strong');
			this.passwordExplanation = this.passwordTitle.nextElementSibling;
			this.usernameTitle = this.page.querySelector('.username-feedback > strong');
			this.usernameExplanation = this.usernameTitle.nextElementSibling;
		},

		// Bind eventTypeData listeners
		bindEvents() {
			this.revealPassword.addEventListener('click', this.reveal.bind(this));
			this.cloakPassword.addEventListener('click', this.cloak.bind(this));
			this.identitySignIn.addEventListener('submit', this.initResponse.bind(this));
			this.passwordId.addEventListener('keyup', this.passwordLengthCheck.bind(this));
			this.usernameId.addEventListener('keyup', this.initResponse.bind(this));
		},

		// Unbind eventTypeData listeners
		unBindEvents() {
			this.revealPassword.removeEventListener('click', this.reveal.bind(this));
			this.cloakPassword.removeEventListener('click', this.cloak.bind(this));
			this.identitySignIn.removeEventListener('submit', this.initResponse.bind(this));
			this.passwordId.removeEventListener('keyup', this.passwordLengthCheck.bind(this));
			this.usernameId.removeEventListener('keyup', this.initResponse.bind(this));
			return this;
		},

		// Render page
		pageRender() {
			const pageData = this.dataBox;
			const blankSpot = '';
			let inputBox,
				inputTitle,
				inputExplanation;

			pageData.forEach(request => {
				// Setup request locations
				if (request.id === this.passwordBox) {
					this.passwordStatus = request.data.response;
					inputBox = this.passwordId;
					inputTitle = this.passwordTitle;
					inputExplanation = this.passwordExplanation;
				} else {
					this.usernameStatus = request.data.response;
					inputBox = this.usernameId;
					inputTitle = this.usernameTitle;
					inputExplanation = this.usernameExplanation;
				}

				// Write data to Dom
				inputTitle.innerHTML = request.data.message.title || blankSpot;
				inputExplanation.innerHTML = request.data.message.explanation || blankSpot;

				// Add styles to Dom
				inputBox.classList.remove(...inputBox.classList);
				inputBox.classList.add('form-control', request.data.response.toString());
			});
		},

		// Validate data
		validate() {
			const inputBox = this.inputId;

			const boxArray = [];
			let response,
				results,
				buildObj;

			inputBox.forEach(request => {
				// Determine the input value
				response = (request === this.passwordBox) ? this.passwordId : this.usernameId;

				// Get the results
				results = verify.init(request, response.value);

				// Build the object data
				buildObj = {
					'id': request,
					'data': results
				};

				// Push data to array box
				boxArray.push(buildObj);
			});

			// Set response to data module
			this.dataBox = boxArray;
			return this;
		},

		// Set the input box to use for data submission
		setInputBox() {
			const id = this.targetId;
			const boxArray = [];

			// Clear the array cache
			this.inputId = [];

			// Initialize responses type
			if (this.eventType === 'submit') {
				// Sets the form to the module
				this.formData = this.identitySignIn;

				Array.from(this.formData.getElementsByTagName('input'))
					.forEach(dataInput => {
						// Push data to array box
						boxArray.push(dataInput.id);
					});
			} else {
				boxArray.push(id);
			}

			// Set the input value id
			this.inputId = boxArray;
			return this;
		},

		// Turn off status of elements
		elementOff() {
			// Set submit button
			const theButton = this.submitButton;

			// Current spinner from FontAwesome
			const spinner = theButton.previousElementSibling;

			// Change element states
			theButton.disabled = true;
			theButton.innerText = 'Confirming';

			// Change element styles
			theButton.classList.add('button-off');
			spinner.classList.remove('d-none');
			return this;
		},

		// Form submit functions
		redirectPage(data) {
			window.location.assign(data);
		},

		// Check password length requirement
		passwordLengthCheck(e) {
			common.ToolBox.lengthCheck = this.passwordId.value;

			if (common.ToolBox.lengthCheck) {
				this.initResponse(e);
			}
		},

		// Generate new page element
		generatePage(page) {
			document.querySelector('.main-content').innerHTML = page;
		},

		// Form submit functions
		sendForm() {
			// Input box in form to submit
			const inputBox = this.formData.getElementsByTagName('input');

			let goObj = {};

			Array.from(inputBox).forEach( item => {
				goObj[item.id] = item.value;
			});

			return new Promise((resolve, reject) => {
				postData(goObj, this.formData.id)
					.then(response => {
						resolve(response);
					})
					.catch(err => {
						reject(err);
					});
			});
		},

		// Start up cookies
		initCookie() {
			common.Cookie.setCookie();
			common.Cookie.readCookie();
			return this;
		},

		// Allows view of the password
		reveal() {
			this.cloakPassword.classList.remove('d-none');
			this.revealPassword.classList.add('d-none');
			this.passwordId.type = 'text';
			this.passwordId.focus();
		},

		// Cloaks the password
		cloak() {
			this.revealPassword.classList.remove('d-none');
			this.cloakPassword.classList.add('d-none');
			this.passwordId.type = 'password';
			this.passwordId.focus();
		},

		// Controller
		initResponse(e) {
			// Set eventTypeData type to the module
			this.eventType = e.type;

			// Set target ID
			this.targetId = e.target.id;

			// Prevent form submission
			e.preventDefault();

			this.setInputBox() // Set Input box
				.validate()
				.pageRender();

			// Validate
			if (this.usernameStatus &&
				this.passwordStatus &&
				this.eventType === 'submit') {
				this.elementOff()
					.sendForm()
					.then(response => {
						if (response.userData.result)  {
							this.unBindEvents()
								.initCookie()
								.redirectPage(response.userData.redirect);
						} else {
							message.init.requestedPage = 'signIn';
							this.unBindEvents()
								.generatePage(message.init.requestedPage.htmlMarkup);
						}
					})
					.catch(() => {
						message.init.requestedPage = 'errorMessage';
						this.unBindEvents()
							.generatePage(message.init.requestedPage.htmlMarkup);
					});
			}
		}
	};

	listening.init();
};

/**
 * Assembles the requested module elements and listeners
 *
 * Return requested module elements and listeners
 *
 * @returns {{htmlMarkup: *, pageListeners: *}} -> Requested module elements and listeners
 */
const init = {
	get requestedPage() {
		return {
			htmlMarkup: factory.init('logIn'),
			pageController: [pageListeners]
		};
	}
};

module.exports = {
	generateElement: factory,
	pageListeners: pageListeners,
	init: init
};
