'use strict';

const getElement = require('./_welcome-markup');
const common = require('../common/_index');

/**
 * Assembles the requested module elements, then
 * returns requested module elements
 *
 * @param pageRequest
 * @returns {string} -> HTML markup
 */
const init = pageRequest => common.assembler.generatePageTemplate(getElement[pageRequest]);

module.exports = {
	common: common,
	getElement: getElement,
	init: init
};
