'use strict';

const messages = require('../common/_verification-message');

const VerifyRegex = {

	emailValidation: /^([\w+]+)@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.)|(([\w-]+\.)+))([\w]{2,4}|[\d]{1,3})(\]?)$/,
	reqPasswordLength: /^\S{8,256}$/, // No space, 8 min, 256 max
	reqUsernameLength: /^\S{1,80}$/, // No space, 1 min, 80 max
	reqWords: /[\w]/,
	exSpecials: /^[-\w.+:!]+$/, // Only period, plus, exclamation, colon, underscore, hyphen
	onlyAllowedCharacters: /^[a-zA-Z0-9!$%^&*()_+|~=`{}\-[\]:/;<>?,.@#]*$/,
	fresh: true,
	testType: 'password',
	verificationValue: '',
	regexValue: '',

	set controllerData(data) {
		this.verificationValue = data;
		console.log(this.verificationValue);
	},

	set requestTest(data) {
		this.testType = data;
		console.log(this.testType);
	},

	get testArray() {
		let testData;

		if (this.testType === 'password') {
			testData = [this.reqPasswordLength, this.onlyAllowedCharacters];
		} else if (this.testType === 'username') {
			testData = [this.reqUsernameLength, this.reqWords, this.exSpecials];
		} else {
			testData = [this.emailValidation];
		}

		return testData;
	},

	get testRegex() {
		// Scope variables
		let responseMessage;

		this.fresh = this.testArray.every(this.testingRegex.bind(this));
		responseMessage = (!this.fresh) ? this.testResponse(this.regexValue) : {};

		return {
			'response': this.fresh,
			'message': responseMessage
		};
	},

	testResponse(data) {
		let responseMessage;

		switch (data) {
		case this.reqPasswordLength:
			responseMessage = this.minPasswordLengthMessage;
			break;
		case this.reqUsernameLength:
			responseMessage = this.minUsernameLengthMessage;
			break;
		case this.onlyAllowedCharacters:
			responseMessage = this.restrictedCharacterMessage;
			break;
		case this.emailValidation:
			responseMessage = this.emailErrorMessage;
			break;
		case true:
			responseMessage = this.repeatCharacterMessage;
			break;
		default:
			responseMessage = false;
			break;
		}
		return responseMessage;
	},

	testingRegex(regexData) {
		this.regexValue = regexData;
		return regexData.test(this.verificationValue);
	}
};

const VerifyPassword = {
	sequentialNumbers: '0123456789876543210',
	sequentialLetters: 'abcdefghijklmnopqrstuvwxyzyxwvutsrqponmlkjihgfedcba',
	repetitiveCharacters: /(.)\1\1+/g,
	captureNumbers: /(\d{3,})/g,
	captureLetters: /([A-Za-z]{3,})/g,

	captureIt(searchType) {
		let fn,
			captureArray,
			captureData;

		switch (searchType) {
		case 'number':
			fn = this.captureNumbers;
			captureArray = this.numberList = [];
			break;
		case 'letter':
			fn = this.captureLetters;
			captureArray = this.textList = [];
			break;
		default:
			fn = this.repetitiveCharacters;
			captureArray = this.repeatList = [];
		}

		while ((captureData = fn.exec(this.verificationValue)) !== null) {
			captureArray.push([captureData[0], captureData.index, (fn.lastIndex - 1)]);
		}
		return this;
	},

	testSequential(valArray, searchType) {
		const seqType = (searchType === 'number') ? this.sequentialNumbers : this.sequentialLetters;
		let seqArray,
			seqResults;

		seqResults = valArray.filter(a => {
			seqArray = seqType.indexOf(String(a[0]).toLowerCase());
			return (seqArray !== -1) ? a : false;
		});
		return seqResults;
	},

	combineIt(valArray) {
		valArray.forEach(a => {
			if (typeof a !== 'undefined') {
				this.combineList.push(a);
			}
		});
		return this;
	},

	captain() {
		let textResults,
			numberResults,
			repeatResults,
			responseMessage,
			responseStatus;

		// Capture all groups of letters, number, or repeating characters
		this.captureIt('letter').captureIt('number').captureIt('repeater');

		// Letter sequential
		textResults = this.testSequential(this.textList, 'letter');

		// Number sequential
		numberResults = this.testSequential(this.numberList, 'number');

		// Repeating characters
		repeatResults = this.repeatList;

		// Combine all testing results
		this.combineList = [];
		this.combineIt(textResults).combineIt(numberResults).combineIt(repeatResults);

		// Respond to results on the list
		if (this.combineList.length) {
			responseMessage = this.testResponse(true);
			responseStatus = false;
		} else {
			responseMessage = {};
			responseStatus = true;
		}
		return {
			'response': responseStatus,
			'message': responseMessage
		};
	}
};

const controller = Object.assign(VerifyRegex, VerifyPassword, messages.VerificationMessages);

const init = (request, requestData) => {
	let verificationResponse,
		trimData;

	console.log(request);
	console.log(requestData);

	const verifyHandler = {
		stringCount: '',
		verifyType: '',
		set requestType(data) {
			this.verifyType = data;
			console.log(controller.testType);
		},
		set dataLength(data) {
			this.stringCount = data;
		},
		passwordRequest() {
			if (this.stringCount < 8) {
				return  {
					'response': false,
					'message': controller.minPasswordLengthMessage
				};
			} else if (this.stringCount > 256) {
				return  {
					'response': false,
					'message': controller.maxPasswordLengthMessage
				};
			} else {
				return this.stringCleaner();
			}
		},
		usernameRequest() {
			if (this.stringCount < 1) {
				return  {
					'response': false,
					'message': controller.minUsernameLengthMessage
				};
			} else if (this.stringCount > 80) {
				return  {
					'response': false,
					'message': controller.maxUsernameLengthMessage
				};
			} else {
				return  {
					'response': true,
					'message': ''
				};
			}
		},
		emailRequest() {
			if (this.stringCount < 1) {
				return  {
					'response': false,
					'message': controller.minEmailLengthMessage
				};
			} else {
				return this.stringCleaner();
			}
		},
		stringCleaner() {
			let cleanString;

			controller.controllerData = requestData.trim();
			controller.requestTest = request;
			cleanString = controller.testRegex;

			console.log('----');
			console.dir(cleanString);

			if (this.stringCount < 12 && this.verifyType === 'password' && cleanString.response) {
				cleanString = controller.captain();
			}
			return  cleanString;
		}
	};

	// Trim request string for dashes
	if (/[-]/.test(request)) {
		trimData = request.split('-', 1);
		request = trimData[0];
	}

	console.log('the request: ' + request);
	verifyHandler.requestType = request;
	verifyHandler.dataLength = requestData.length;

	if (request === 'password') {
		verificationResponse = verifyHandler.passwordRequest();
	} else if (request === 'username') {
		verificationResponse = verifyHandler.usernameRequest();
	} else {
		verificationResponse = verifyHandler.emailRequest();
	}
	return verificationResponse;
};

module.exports = {
	init: init,
	controller: controller
};
