'use strict';

const getElement = require('./_privacyComm-markup');

const privacyCommunicationFactory = {
	/**
	 * Generate radio buttons for Email Marketing Preferences
	 *
	 * @param preference -> 'week', 'month', 'quarter', 'Never'
	 * @param data -> Request database information
	 * @returns {string}
	 */
	generatePreferences(preference, data) {
		// Set form element ID
		const formId = 'marketing-preferences';

		// Set counter value
		let i = 0;

		// Scope variables
		let buildHtml = '';
		let s,
			html,
			checkThis,
			dropZoneMap,
			preFix;

		preference.forEach(item => { // Create all requested time periods
			i++; // Start counter
			checkThis = data === item ? 'checked' : ''; // Assign checked value
			preFix = item !== 'Never' ? 'Once a ' : ''; // Assign prefix to text
			dropZoneMap = {}; // Create drop zone map
			dropZoneMap = { // Map drop zone target
				'%formId%': formId + i,
				'%formName%': formId,
				'%timeRequired%': item,
				'%formIdLabel%': formId + i,
				'%timeRequiredDisplay%': preFix + item,
				'%checked%': checkThis
			};
			html = getElement.emailMarketingPreferencesElement; // Get form elements

			for (s in dropZoneMap) { // Air drop targets
				if (typeof dropZoneMap[s] !== 'undefined') {
					html = html.replace(s, dropZoneMap[s]);
				}
			}
			buildHtml += html; // Assemble  all email marketing preference
		});

		// Construct and return email marketing preferences
		return getElement.formWrap.replace('%content%', buildHtml);
	},
	getMarkup(element) {
		return getElement[element];
	}
};

module.exports = {
	Factory: privacyCommunicationFactory
};
