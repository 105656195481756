'use strict';

const pageTemplate = require('./_pageTemplate');
const accordion = require('./_accordion');

// Identity API endpoint
const endpoint = '/index.php?action=';
const mendpoint = '/identityApi.php?action=';

const Master = {
	/**
	 * Assign values to the object
	 *
	 * @param arg
	 * @returns {Master}
	 */
	setValue(...arg) {
		Object.assign(this, ...arg );
		return this;
	},

	/**
	 * Create and returns a new Object with Master inheritance
	 *
	 * @param values
	 * @returns {Master}
	 */
	create(values) {
		let instance = Object.create(this);

		Object.keys(values).forEach(function(key) {
			instance[key] = values[key];
		});
		return instance;
	}
};

const Cookie = {
	readCookie() {
		console.dir(document.cookie);
	},
	setCookie() {
		console.log(document.URL);
		document.cookie = 'shmoop=true';
	},
	delCookie() {
		document.cookie = 'shmoop= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
	},
	getCookie(name) {
		let value = '; ' + document.cookie;
		let parts = value.split('; ' + name + '=');

		if (parts.length === 2) {
			return parts.pop().split(';').shift();
		} else {
			return null;
		}
	}
};

const ToolBox = {
	inputValue: '',
	set lengthCheck(data) {
		this.inputValue = data;
	},
	get lengthCheck() {
		return this.inputValue.length >= 8;
	}
};

const Connect = {
	/**
	 * Fetch API
	 *
	 * Used to fetch API calls
	 *
	 * @param request -> API endpoint, data and headers
	 * @param callerMessage -> The originated request message
	 * @returns {Promise} -> JSON data
	 */
	goFetch(request, callerMessage) {
		console.log('goFetch');

		return new Promise( resolve => {
			resolve(
				fetch(request)
					.then(response => { // Fetch response
						if (!response.ok)  {    // Handles Fetch OK response
							throw response;
						}
						return response.json();
					})
					.catch(err => {   // TODO Refactor code block and send error information to kibana
						if(!err.length && err.status > 200) {  // Handles empty response
							console.log(`${err.status} ${err.statusText} fetch ${callerMessage}`);
							err = err.status;
						} else {
							console.log('No content');
							err = 204;  // Status 204 : no content
						}
						return err;
					})
			);
		});
	},

	/**
	 * XMLHttpRequest (XHR) objects
	 *
	 * Used if Fetch API is not available
	 *
	 * @param apiLocation -> Identity API endpoint
	 * @param callerMessage -> The originated request message
	 * @param data -> JSON data to be updated
	 * @returns {Promise} -> JSON
	 */
	goXHR(apiLocation, callerMessage, data) {
		console.log('goXHR');

		// Create AJAX
		let ajax = new XMLHttpRequest();

		// Handles post data
		let postData = (typeof data !== 'undefined') ? JSON.stringify(data) : null;

		// Handles method type action
		let methodType = data ? 'POST' : 'GET';

		// Scope variables
		let err;

		return new Promise((resolve, reject) => {
			ajax.onload = () => { // Successful ajax call
				if (ajax.readyState === 4 && ajax.status === 200 && ajax.responseText) { // Success response
					resolve(JSON.parse(ajax.responseText));
				} else { // Failed response
					// TODO Refactor code block and send error information to kibana
					if (!ajax.responseText && ajax.status > 200) {
						console.log(`${ajax.status} ${ajax.statusText} ${callerMessage}`);
						err = ajax.status;
					} else {
						console.log('No content');
						err = 204;  // Status 204 : no content
					}
					reject(err);
				}
			};
			ajax.open(methodType, apiLocation, true);
			ajax.setRequestHeader('Content-Type', 'application/json');
			ajax.send(postData);
		});
	},

	/**
	 * Get data by AJAX
	 *
	 * Make GET request via Fetch, fallback: XHR
	 *
	 * @param ajaxRequest -> Identity API request
	 * @param callerMessage -> The originated request message
	 * @param travel
	 * @returns {Promise} -> JSON
	 */
	getAjaxCall(ajaxRequest, callerMessage, travel = true) {
		// @todo: fix or remove this
		let goLink = (travel) ? endpoint : mendpoint;

		// Assemble API endpoint call
		let apiLocation = `${goLink}${ajaxRequest}`;

		// Assign request for Fetch API
		let request = new Request(apiLocation, {
			method: 'GET',
			mode: 'same-origin',
			redirect: 'follow',
			headers: new Headers({
				'Content-Type': 'application/json',
				'ReadOnlySession': false
			})
		});

		// Scope variables
		let getAjax;

		console.log('travel: ' + travel);
		console.log(`API call: ${goLink}${ajaxRequest}`);

		// Make AJAX request
		getAjax = (window.fetch) ? this.goFetch(request, callerMessage) : this.goXHR(apiLocation, callerMessage);

		return new Promise((resolve, reject) => {
			getAjax.then(getData => {
				if (!isNaN(getData)) { // Handles status code response
					console.log(typeof getData);
					throw getData;
				}
				console.log(typeof getData);
				resolve(getData);
			}).catch(err => {   // Handles AJAX error
				console.log(`${err} from getAjaxCall`);
				reject(err);
			});
		});
	},

	/**
	 * Post data by AJAX
	 *
	 * Make POST request via Fetch, fallback: XHR
	 *
	 * @param data -> Data to be updated
	 * @param formId -> Current form submission
	 * @param travel
	 * @returns {Promise} -> JSON
	 */
	postAjaxCall(data, formId, travel = true) {
		// Set the originated request message
		let callerMessage = `from ${formId}`;

		// Temporally key for JSON data
		// let keyName = 'userData';

		// Create location to store JSON data
		let dataArray = data;

		// Scope variables
		let ajaxRequest,
			apiLocation,
			postAjax,
			request,
			goLink;

		switch (formId) {   // Sort request form parameters
		case 'identity-create-account':
			ajaxRequest = 'PostCreateUser';    // Ajax gateway
			break;
		case 'identity-password-reset':
			ajaxRequest = 'PostForgotPassword';    // Ajax gateway
			break;
		case 'identity-logIn':
			ajaxRequest = 'PostAuthenticateUser';    // Ajax gateway
			break;
		case 'identity-signUp':
			ajaxRequest = 'signUp';    // Ajax gateway
			break;
		case 'form-username':
			ajaxRequest = 'updateUsername';    // Ajax gateway
			break;
		case 'form-email':
			ajaxRequest = 'updateEmail';    // Ajax gateway
			break;
		case 'form-password':
			ajaxRequest = 'updatePassword';    // Ajax gateway
			break;
		case 'sign-out-form':
			ajaxRequest = 'PostSignOutUser';    // Ajax gateway
			break;
		default:
			console.log('empty ajaxRequest');
			break;
		}

		// @todo: fix or remove this
		goLink = (travel) ? endpoint : mendpoint;

		console.log('form ID: ' + formId);
		console.log(`API call: ${goLink}${ajaxRequest}`);
		console.dir(dataArray);
		apiLocation = `${goLink}${ajaxRequest}`;  // Assemble API endpoint and request
		request = new Request(apiLocation, {    // Assign Fetch API endpoint and headers
			method: 'POST',
			mode: 'same-origin',
			redirect: 'follow',
			body: JSON.stringify(dataArray),
			headers: new Headers({
				'Content-Type': 'application/json',
				'ReadOnlySession': false
			})
		});

		// Make AJAX request
		postAjax = (window.fetch) ? this.goFetch(request, callerMessage)
			: this.goXHR(apiLocation, callerMessage, dataArray);

		return new Promise((resolve, reject) => {
			postAjax.then(postData => {
				if (!isNaN(postData)) { // Handles status code response
					console.log(typeof postData);
					throw postData;
				}
				console.dir(postData);
				resolve(postData);
			}).catch(err => {   // Handle fetch error
				console.log(`${err} from postAjaxCall`);
				reject(err);
			});
		});
	},


	/**
	 * Determines what module is requested
	 *
	 * @returns {*} -> module request
	 */
	urlRequest() {    // TODO Refactor code block and adapt to BE response
		let url = new URL(window.location.href);
		let dataType = new URLSearchParams(url.search);
		let identityType = [];
		let key;

		for(key of dataType.keys()) {
			if (identityType.indexOf(key) > -1) {
				identityType.splice(identityType.indexOf(key), 1);
			}
			identityType.push(key);
		}

		if (!Array.isArray(identityType) || !identityType.length) {
			identityType.push('signIn');
		}

		return identityType[identityType.length - 1];
	}
};

const assembler = {
	/**
	 * Assemble module markups
	 *
	 * @param data -> [html markup, eventTypeData listeners]
	 * @returns {string} -> HTML markup
	 */
	assembleModules(data) {
		// Map out data array to first item
		let module = data.map(item => item[0]);

		// Scope variables
		let htmlModule = '';

		module.forEach(item => htmlModule += item); // Assemble the modules

		return htmlModule; // Return Account Settings module markup
	},

	/**
	 * Assemble module eventTypeData listeners
	 *
	 * @param data -> [html markup, eventTypeData listeners]
	 * @returns {*} -> JSON
	 */
	assembleListeners(data) {
		// Scope variables
		let assembleFn;

		assembleFn = data.map(value => value[1]);
		return assembleFn;
	},

	/**
	 * Assemble accordion
	 *
	 * @param title -> Title of content
	 * @param preferenceBuild -> Generated request modules
	 * @returns {string} -> HTML markup
	 */
	assembleAccordion(title, preferenceBuild) {
		return accordion.Factory.accordionElementBuild(title, preferenceBuild);
	},

	/**
	 * Assemble accordion wrapper
	 *
	 * @param title -> Title of content
	 * @param accordionName -> Set ID of the accordion
	 * @param accordionContent -> Content to display
	 * @returns {string} -> HTML markup
	 */
	assembleAccordionWrap(title, accordionName, accordionContent) {
		return accordion.Factory.accordionWrapBuild(title, accordionName, accordionContent);
	},

	/**
	 * Generate Page Template
	 *
	 * @param htmlBuild
	 * @returns {string} -> HTML markup
	 */
	generatePageTemplate(htmlBuild) {
		return pageTemplate.init(htmlBuild);
	}
};

const retrieve = Object.assign(assembler, {
	/**
	 * Generate Promises
	 *
	 * @param requestItem -> module requested
	 * @param pageRequest -> Request from URL parameter : google, user, account, logIn, resetPassword, email
	 * @param data -> data to drive module
	 * @returns {Promise}
	 */
	makePromises(requestItem, pageRequest, data) { // TODO Refactor code block and adapt to BE response
		// Scope variables
		let pact;

		return new Promise(resolve => { // Returns generated Promise
			pact = requestItem(data, pageRequest); // Construct the module request
			resolve([this.generatePageTemplate(pact[0]), pact[1]]); // Assemble with page template
		});
	},

	/**
	 * Full fill all promises
	 *
	 * @param pageRequest -> Request from URL parameter : google, user, account, logIn, resetPassword, email
	 * @param modules -> All requested modules
	 * @param data -> Request database information
	 * @returns {Promise}
	 */
	fulfillPromises(pageRequest, modules, data) {
		// Create a location to store all the Promises
		let pledge = [];

		// Create a location to store all fulfilled Promises
		let oath;

		modules.forEach(requestItem => { // Generate all requested Promises
			pledge.push(this.makePromises(requestItem, pageRequest, data)); // Store each Promise
		});
		oath = Promise.all(pledge); // Assign all pledges to an oath

		// Return Promises
		return new Promise(
			resolve => {
				resolve( oath.then( dataMine => {
					return {
						htmlMarkup: this.assembleModules(dataMine),
						pageController: this.assembleListeners(dataMine)
					};
				}));
			});
	},

	/**
	 * Alert dialog handler
	 */
	alertHandler(message) {
		const alertMessage = message || 'Something weird just happened. Please contact Shmoop.';

		alert(alertMessage);
	}
});

module.exports = {
	Master: Master,
	Connect: Connect,
	Cookie: Cookie,
	ToolBox: ToolBox,
	retrieve: retrieve,
	assembler: assembler
};
