'use strict';

const VerificationMessages = {
	minPasswordBreached: {
		'title': 'Your password is starting to gain a reputation with folks of ill repute.',
		'explanation': 'We recommend using a different password, as the one you entered has been ' +
			'found less than 100 times in  ' +
			'<a href="https://haveibeenpwned.com/FAQs">publicly disclosed data breaches</a>.'
	},
	maxPasswordBreached: {
		'title': 'Your password is well-known amongst the underground.',
		'explanation': 'The password you entered is widely seen in ' +
			'<a href="https://haveibeenpwned.com/FAQs">publicly disclosed data breaches</a>' +
			', please pick a new password.'
	},
	minPasswordLengthMessage: {
		'title': 'Your password is too short to enter.',
		'explanation': 'Our security standards require passwords to be at least 8 characters long, ' +
			'please enter a longer password.'
	},
	minUsernameLengthMessage: {
		'title': 'Your username is too short to enter.',
		'explanation': 'Our security standards require usernames to be at least 1' +
			' character long, please enter a longer username.'
	},
	minEmailLengthMessage: {
		'title': 'Your email is too short to enter.',
		'explanation': 'Our security standards require to be longer.'
	},
	emailErrorMessage: {
		'title': 'Your email is bad.',
		'explanation': 'Our security standards require emails must contain at least 1 @ symbol and 1 period.'
	},
	maxUsernameLengthMessage: {
		'title': 'Brevity is the soul of wit.',
		'explanation': 'Sorry, we only allow usernames up to 80 characters. ' +
			'Apologies to "Red Wacky League Antlez Broke the Stereo Neon Tide Bring Back Honesty Coalition Feedback..."'
	},
	maxPasswordLengthMessage: {
		'title': 'Your password is like Game of Thrones, too many characters.',
		'explanation': 'The max password length is 256 characters. Please enter a shorter password.'
	},
	restrictedCharacterMessage: {
		'title': 'We don\'t serve those characters here!',
		'explanation': 'Specifically, you can\'t use the strange characters in a password, please enter a new one.'
	},
	repeatCharacterMessage: {
		'title': 'Your password is like a toddler\'s riddle. Too easy to guess.',
		'explanation': 'Please enter a password with less repeated characters (aaa, 111, abc, 123, etc)'
	},
	breachedPasswordMessage: {
		'title': 'Your password is well-known amongst the underground.',
		'explanation': 'We recommend using a different password, as the one you entered ' +
			'has been found less than 100 times in  <a href="https://haveibeenpwned.com/FAQs">' +
			'publicly disclosed data breaches</a>.'
	}
};

module.exports = {
	VerificationMessages: VerificationMessages
};
