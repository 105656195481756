'use strict';

const getElement = require('./_pageTemplate-markup');

/**
 * Initialize module
 *
 * Build Page Templates
 *
 * @param requestForm
 * @returns {string}
 */
const init = requestForm => {
	let htmlBuild = getElement.buildCard;

	return htmlBuild.replace('%requestForm%', requestForm);
};

module.exports = {
	init: init
};
