'use strict';

const fn = require('./identity/_index');
const identity = fn.init;

// DOM fully loaded and parsed, set the current Identity flow
window.addEventListener('DOMContentLoaded', () => identity());

module.exports = {
	init: identity
};
