'use strict';

let getElement = require('./_accordion-markup');

const accordion = {
	/**
	 *
	 * Build outer accordion element
	 *
	 * @param title
	 * @param accordionName
	 * @param accordionContent
	 * @returns {string}
	 */
	accordionWrapBuild(title, accordionName, accordionContent) {
		// Map drop zone targets
		const dropZoneMap = {
			'%title%': title,
			'%accordions-name%': accordionName,
			'%accordions%': accordionContent
		};

		// Get accordion wrap markup
		let html = getElement.accordionWrap;

		// Scope variables
		let s;

		for (s in dropZoneMap) {  // Air drop targets
			if (typeof dropZoneMap[s] !== 'undefined') {
				html = html.replace(s, dropZoneMap[s]);
			}
		}

		return html;
	},
	/**
	 *
	 * Create Accordion element
	 *
	 * @param title
	 * @param preferenceElements
	 * @returns {string}
	 */
	accordionElementBuild(title, preferenceElements) {
		// title -> Replace space with dash, set lowercase, assign to accordion element
		const switchName = title.replace(/\s+/g, '-').toLowerCase();

		// Map drop zone targets
		const dropZoneMap = {
			'%switch-name%': switchName,
			'%switch-name-id%': switchName,
			'%title%': title
		};

		// Get accordion elements
		let html = getElement.accordionElement;

		// Scope variables
		let s;

		for (s in dropZoneMap) { // Air drop targets
			if (typeof dropZoneMap[s] !== 'undefined') {
				html = html.replace(s, dropZoneMap[s]);
			}
		}

		return html.replace('%content%', preferenceElements); // Construct and returns the accordion
	}
};

module.exports = {
	Factory: accordion
};
