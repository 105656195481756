'use strict';

/**
 * HTML Markup
 *
 * @type {string}
 */
const formWrap = // Form wrapper
	`<form class="p-4">
		%content% </form>`;

const emailMarketingPreferencesElement = // Email Marketing Preference form elements
	`<div class="custom-control custom-radio">
  	<input type="radio" class="custom-control-input" name="%formName%" id="%formId%" value="%timeRequired%" %checked%>
  	<label class="custom-control-label" for="%formIdLabel%">%timeRequiredDisplay%</label>
	</div>`;

const personaDataElements = // Personal Data toolset
	`<div class="p-4"><p><a href="" class="button-link" data-id="download">Download my data</a></p>
		<p><a href="" class="button-link" data-id="delete">Delete my data</a></p></div>`;

module.exports = {
	formWrap: formWrap,
	personaDataElements: personaDataElements,
	emailMarketingPreferencesElement: emailMarketingPreferencesElement

};
