'use strict';

const getElement = require('./createAccount-markup');
const common = require('../common/_index');

/**
 * Input element values.
 *
 * available: 'birth date', 'user type',
 * @type {string[]}
 */
const reqElement = [
	'username', 'password', 'given name', 'family name', 'email address'];

/**
 * Factory to build out requested form
 *
 * @type {{generateFormElements(*): string, generateOption(*, *=): string, requestedForm(): string}}
 */
const createAccountFactory = {
	/**
	 * Generate option list for form.
	 *
	 * mx is the maximum ending amount.
	 * mn is the starting amount.
	 *
	 * @param mx
	 * @param mn
	 * @returns {string}
	 */
	generateOption(mx, mn = 1) {
		// Scope Variables
		let min = mn;
		let max = mx;
		let inputElement = '';

		// loop and create option element
		for (min; min <= max; min++) {
			inputElement += `<option value="${min}">${min}</option>`;
		}

		return inputElement;
	},

	/**
	 * Generates the form elements needed.
	 *
	 * @param newItem
	 * @returns {string}
	 */
	generateFormElements(newItem) {
		// Scope Variables
		let formElement,
			inputType,
			inputElements,
			s,
			dropZoneMap,
			typeA,
			typeB,
			typeC,
			year,
			month,
			day;

		// Variables used for id, class and text content
		typeA = newItem.replace(/\s/g, '-'); // replace space with dash

		typeB = newItem.split(/\s/g); // Split value with space

		typeC = typeB[0].charAt(0).toUpperCase() + typeB[0].slice(1); // Mutate uppercase to first word

		inputType = (newItem === 'password' || newItem === 'email') ? newItem : 'text'; // Determine form input type

		dropZoneMap = { // Create drop zone map
			'%label-for%': typeA,
			'%label%': typeB[1] ? `${typeC} ${typeB[1]}` : typeC,
			'%input-type%': inputType,
			'%input-id%': typeA,
			'%holder%': typeB[1] ? `${typeC} ${typeB[1]}` : typeC,
			'%feedback%': typeA
		};

		// Get requested form elements markup
		switch (newItem) {
		case 'password':
			inputElements = `${getElement.label} ${getElement.password}`;
			inputElements = inputElements.replace('%for-input%', getElement.inputElement);
			inputElements = inputElements.replace('%for-feedback%', getElement.feedBack);
			break;
		case 'user type':
			inputElements = `${getElement.label} ${getElement.userType} ${getElement.feedBack}`;
			break;
		case 'birth date':
			inputElements = `${getElement.label} ${getElement.birthDate} ${getElement.feedBack}`;

			// Build drop down list option
			day = this.generateOption(31); // Build day list
			month = this.generateOption(12); // Build month list
			year = this.generateOption(2020, 2000); // Build year list
			inputElements = inputElements.replace('%for-day%', day);
			inputElements = inputElements.replace('%for-month%', month);
			inputElements = inputElements.replace('%for-year%', year);
			break;
		default:
			inputElements = `${getElement.label} ${getElement.inputElement} ${getElement.feedBack}`;
		}

		formElement = getElement.formGroup; // Get html markup

		formElement = formElement.replace('%element%', inputElements);

		for (s in dropZoneMap) { // Air drop targets
			if (typeof dropZoneMap[s] !== 'undefined') {
				formElement = formElement.replace(s, dropZoneMap[s]);
			}
		}

		return formElement;
	},

	/**
	 * Controls the form build request.
	 *
	 * Values for form elements are from reqElement array.
	 *
	 * @returns {string}
	 */
	requestedForm() {
		// Scope variables
		let html;
		let htmlForm = '';

		reqElement.forEach( newItem => { // Generate requested elements
			htmlForm += this.generateFormElements(newItem);
		});

		html = getElement.createAccountForm; // Get html markup

		// Builds the form
		html = html.replace('%for-elements%', htmlForm);
		html = html.replace('%for-disclaimer%', getElement.disclaimer);
		html = html.replace('%for-button%', getElement.buttonElement);
		return html;
	}
};

/**
 * Assembles the requested module elements, then
 * returns requested module elements
 *
 * @returns {string} -> HTML markup
 */
const init = () => common.assembler.generatePageTemplate(createAccountFactory.requestedForm());

module.exports = {
	common: common,
	getElement: getElement,
	Factory: createAccountFactory,
	init: init
};
