'use strict';

const generateElement = require('./_signInDetails-factory');

/**
 * Create Detail Sign-In Module
 *
 * @type {{readonly dropZoneMap: *, readonly requestElements: *,
 * setMessage(): *, setInputType(): void, setModuleValue(*, *): void}}
 */
const signInDetailsCommon = {
	// Set form message
	setMessage() {
		// Assign text
		switch (this.item) {
		case 'username':
			this.message = 'A username must contain at least 6 characters.';
			break;
		case 'password':
			this.message = 'A password must contain at least 8 characters, 1 number, and 1 special character.';
			break;
		case 'email':
			this.message = 'A email must contain at least 1 @ symbol and 1 period.';
			break;
		}
		return this;
	},
	// Create input field type
	setInputType() {
		this.inputBox = (this.item === 'password') ? generateElement.Factory.generatePasswordElement()
			: generateElement.Factory.generateInputElement();
	},
	// List of requested form elements
	get requestElements() {
		return [
			[this.inputBox, generateElement.Factory.requestMarkup('detailCancelButton')],
			[generateElement.Factory.requestMarkup('detailMessage'),
				generateElement.Factory.requestMarkup('spinner') +
					generateElement.Factory.requestMarkup('detailChangeButton')]
		];
	},
	// Map drop zone targets
	get dropZoneMap() {
		return {
			'%label-name%': this.item + '-detail',
			'%label%': 'New ' + this.item,
			'%formId%': this.item + '-detail',
			'%formType%': this.formType,
			'%detailMessage%': this.message,
			'%cancel-id-target%': this.item,
			'%cancel-id%': this.item
		};
	},
	// Set module values
	setModuleValue(item, formType) {
		Object.assign(this, { item, formType });
		this.setMessage().setInputType();
	}
};

/**
 * Initialize module
 *
 */
const init = (item, formType) => {
	signInDetailsCommon.setModuleValue(item, formType);
	return generateElement.Factory.generateForm(signInDetailsCommon.requestElements, signInDetailsCommon.dropZoneMap);
};

module.exports = {
	Builder: signInDetailsCommon,
	generateElement: generateElement,
	init: init
};
