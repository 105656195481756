'use strict';

const common = require('./_index');
const signInDetails = require('../signInDetails/_index');
const subscriptionDetails = require('../subscriptionDetails/_index');
const privacyCommunication = require('../privacyCommunication/_index');

/**
 * Contains building functions
 *
 * @type {{pageBuilder(*=, *=): Promise, moduleListGenerator(*): *[]}}
 */
const accountSetting = {
	/**
	 * Account Setting page builder
	 *
	 * @param pageRequest -> Request from URL parameter : google, user, account, logIn, resetPassword, email
	 * @param data -> Request database information
	 * @returns {Promise}
	 */
	pageBuilder(pageRequest, data) { // TODO Refactor code block and adapt to BE response
		// Prepare requested modules for export
		const modules = this.moduleListGenerator(pageRequest);

		return common.retrieve.fulfillPromises(pageRequest, modules, data); // Return Promises
	},
	/**
	 * Generate module from page request
	 *
	 * @param pageRequest -> Request from URL parameter : google, user, account, logIn, resetPassword, email
	 * @returns {[]}
	 */
	moduleListGenerator(pageRequest) { // TODO Refactor code block and adapt to BE response
		// Requested Functions
		const x = signInDetails.init;
		const y = subscriptionDetails.init;
		const z = privacyCommunication.init;

		let moduleList = [];
		let multipleModules;

		switch (pageRequest) {
		case 'google':
			moduleList.push(x);
			break;
		case 'user':
			moduleList.push(x);
			break;
		case 'account':
			multipleModules = [x, y, z];
			moduleList.push(...multipleModules);
			break;
		}
		return moduleList;
	}
};

/**
 * Initialize module
 *
 */
const init = (pageRequest, data) => accountSetting.pageBuilder(pageRequest, data);

module.exports = {
	Builder: accountSetting,
	signInDetails: signInDetails,
	subscriptionDetails: subscriptionDetails,
	privacyCommunication: privacyCommunication,
	common: common,
	init: init
};
