'use strict';

const getElement = require('./_signInDetails-markup');

const signInDetailsFactory = {
	requestMarkup(markupRequest) {
		return getElement[markupRequest];
	},
	/**
	 * Generate password element
	 *
	 * @returns {string}
	 */
	generatePasswordElement() {
		let inputBuild;

		inputBuild = getElement.passwordEyeElement.replace('%passwordEye%', getElement.detailInputElement);

		// Add password eyeball button
		inputBuild = inputBuild.replace('%eyeball%', getElement.passwordEyeButton);

		return getElement.detailLabelElement + inputBuild;
	},
	/**
	 * Generate input element
	 *
	 * @param data -> increment for multiple input elements
	 * @returns {string}
	 */
	generateInputElement(data) {
		// Get input markup
		let htmlBuild = getElement.detailLabelElement + getElement.detailInputElement;

		// Create location for replacement map
		let dropZoneMap = {};

		// Scope variables
		let s;

		// Remove password eyeball button placement
		htmlBuild = htmlBuild.replace('%eyeball%', '');

		// Increment dropZoneMap values if more than one input elements are needed.
		if (data) {
			// Map drop zone targets if more than one input elements are needed.
			dropZoneMap = {
				'%label-name%': '%label-name' + data + '%',
				'%label%': '%label' + data + '%',
				'%formType%': '%formType' + data + '%',
				'%formId%': '%formId' + data + '%',
				'%formValue%': '%formValue' + data + '%'
			};

			for (s in dropZoneMap) { // Air drop targets
				if (typeof dropZoneMap[s] !== 'undefined') {
					htmlBuild = htmlBuild.replace(s, dropZoneMap[s]);
				}
			}
		}

		return htmlBuild;
	},
	/**
	 * Generate form input and button element
	 *
	 * @param newItem -> array[ input/message, button/empty element]
	 * @param formRequest
	 * @returns {string}
	 */
	generateFormElements(newItem, formRequest) {
		// Get form group markup
		let inputElements = formRequest ? getElement.signInElement : getElement.detailElement;

		newItem.forEach(item => { // Assemble the form
			inputElements = inputElements.replace('%detailElement%', item);
		});

		return inputElements;
	},
	/**
	 * Generate User change elements: username, email, password
	 *
	 * @param requestElements -> array[ input/message, button/empty element] : requested form elements
	 * @param dropZoneMap -> object{ %item%, value } : map of value to replace in html
	 * @param formRequest
	 * @returns {string}
	 */
	generateForm(requestElements, dropZoneMap, formRequest = false) {
		// Scope variables
		let htmlForm = '';
		let s;

		requestElements.forEach( newItem => { // Generate requested elements
			htmlForm += this.generateFormElements(newItem, formRequest);
		});

		for (s in dropZoneMap) { // Air drop targets
			if (typeof dropZoneMap[s] !== 'undefined') {
				htmlForm = htmlForm.replace(s, dropZoneMap[s]);
			}
		}
		return htmlForm;
	}
};

module.exports = {
	Factory: signInDetailsFactory
};
