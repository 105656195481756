'use strict';

/**
 * HTML Markup
 *
 * @type {string}
 */
const accordionWrap = // Accordion wrapper
	`<div id="%accordions-name%" class="p-4">
		<h2 class="mb-4">%title%</h2>%accordions%</div>`;

const accordionElement = // Accordion elements
	`<h3 class="collapsed arrows-direction mt-3" data-toggle="collapse" data-target="#%switch-name%">%title%</h3>
	<div id="%switch-name-id%" class="collapse" data-parent="#%accordions-name-parent%" data-id="%data-id%">
		%content%</div>`;

module.exports = {
	accordionWrap: accordionWrap,
	accordionElement: accordionElement
};
