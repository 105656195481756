'use strict';

const getElement = require('./_subscriptionDetails-markup');

/**
 * Generate
 *
 * @param dropZoneMap
 * @returns {string}
 */
const generateSubscriptionDetails = dropZoneMap => {
	// Scope variables
	let s;

	// Get subscription markup
	let html = getElement.subscriptionDetail;

	for (s in dropZoneMap) { // Air drop targets
		if (typeof dropZoneMap[s] !== 'undefined') {
			html = html.replace(s, dropZoneMap[s]);
		}
	}
	return html;
};

module.exports = {
	getElement: getElement,
	init: generateSubscriptionDetails
};
