'use strict';

const common = require('../common/_index');
const getElement = require('./_resetPassword-markup');

/**
 * Assembles the requested module elements, then
 * returns requested module elements
 *
 * @param pageRequest -> Request from URL parameter : google, user, account, logIn, resetPassword, email
 * @returns {string} -> HTML markup
 */
const init = () => common.assembler.generatePageTemplate(getElement.resetPasswordForm);

module.exports = {
	common: common,
	getElement: getElement,
	init: init
};
