'use strict';

const generateElement = require('./_privacyComm-factory');
const common = require('../common/_index');

/**
 * Module listeners
 */
const pageListeners = () => {
	$('.radio-adjustment').click(function() {
		console.log('click.' + $(this).prop('id'));
	});
};

/**
 * Create Email Marketing Preferences element
 *
 * @param data -> Request database information
 * @returns {*[]}
 */
const emailPreferences = {
	// Set title of module
	title: 'Email Marketing Preferences',

	// Email Marketing Preferences data
	preference: ['week', 'month', 'quarter', 'Never'],

	// Set module data
	set setData(data) {
		this.data = data;
	},

	// Assign data value
	get checkPreference() {
		return this.data['email preference'];
	},

	// Get all email marketing preferences
	get preferenceBuild() {
		return generateElement.Factory.generatePreferences(this.preference, this.checkPreference);
	},

	// Assemble Email Preference module
	get htmlBuild() {
		return common.assembler.assembleAccordion(this.title, this.preferenceBuild);
	},

	// Construct and returns the accordion
	get htmlPackage() {
		return [this.htmlBuild.replace('%content%', this.preferenceBuild), pageListeners];
	}
};

const init = data => {
	emailPreferences.setData = data;
	return emailPreferences.htmlPackage;
};

module.exports = {
	generateElement: generateElement,
	common: common,
	Factory: emailPreferences,
	init: init
};
