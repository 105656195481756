'use strict';

const generateElement = require('./_subscriptionDetails-factory');

/**
 * Module listeners
 */
const pageListeners = () => {
	$('.subscription-detail-btn').click(function(e) {
		e.preventDefault();
		console.log('click.' + $(this).data('id'));
	});
};

/**
 * Create Subscription-in Details module
 *
 */
const subscriptionDetailBuild = data => {
	// Map drop zone targets
	const dropZoneMap = {
		'%renewalDate%': data['renewal date'],
		'%subscriptionStatus%': data['subscription status']
	};

	return [generateElement.init(dropZoneMap), pageListeners]; // Return subscription details
};

module.exports = {
	generateElement: generateElement,
	init: subscriptionDetailBuild
};
