'use strict';

/**
 * HTML Markup
 *
 * @type {string}
 */


const errorCreateMessage = {
	title: 'OUCH! ',
	message: 'Wow, Create user failed. Please contact support.'
};

const errorMessage = {
	title: 'Error occurred: ',
	message: 'Something weird just happened. Please contact support.'
};

const resetMessage = {
	title: 'Check your email',
	message: 'If you have an account, you should receive a link to reset your password.'
};

const resetMessage2 = {
	title: 'Email Alert!',
	message: 'Your email don\'t exist. Weird right?'
};

const signUpMessage = {
	title: 'Duplicate Login name',
	message: 'Your login name has been taken. Please try again <a href="/?signUp">Try Again</a>'
};

const signInMessage = {
	title: 'Login Alert',
	message: 'Your login did not work. Please try again <a href="/?">Try Again</a>'
};

const messageBoard = // Identity message board
	`<div class="message-board p-5">
		<h1 class="card-title text-center my-5">%title%</h1>
		<p class="my-5 text-center">%subject%</p>
	</div>`;

module.exports = {
	resetMessage: resetMessage,
	resetMessage2: resetMessage2,
	messageBoard: messageBoard,
	signUpMessage: signUpMessage,
	signInMessage: signInMessage,
	errorMessage: errorMessage,
	errorCreateMessage: errorCreateMessage
};
