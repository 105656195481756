'use strict';

/**
 * Identity sign in form
 */
const logIn =
	`<div class="sign-in-form p-5">
	    <h1 class="card-title text-center text-greyscale-11">Log In</h1>	  
	    <a href="#google" class="anchor-button google-button">
	    <img src="https://media1.shmoop.com/identity/btn_google_signin_dark_normal_web.svg"></a>
	    <a href="#clever" class="anchor-button clever-button">
	    <img src="https://media1.shmoop.com/identity/clever-login-button.png"></a>
	    <div class="stripe-line text-center w-75 mx-auto my-4"> or </div>
	    <form id="identity-logIn">
	        <div class="form-group">
	            <label for="username">Username/Email</label>
	            <input type="text" class="form-control" id="username" placeholder="Enter username or email">
	            <div class="invalid-feedback username-feedback this"><strong>Shmoop</strong> <span>Fresh</span></div>
	        </div>
	        <div class="form-group">
	            <label for="password">Password</label>
	            <div class="input-group password-eye">
	                <input type="password" class="form-control" id="password" placeholder="Password">
	                <div class="input-group-append">
	                    <div class="input-group-text">
	                        <i class="fas fa-eye reveal-password"></i>
	                        <i class="fas fa-eye-slash cloak-password d-none"></i>
	                    </div>
	                </div>
	                <div class="invalid-feedback password-feedback this">
	                	<strong>Shmoop</strong> <span>Fresh</span>
	                </div>	     
	            </div>	                   
	        </div>
	        <div class="position-relative">
	        <i class="fas fa-spinner fa-spin spinning-spinner d-none"></i>
	        <button name="signIn-button" type="submit" class="btn btn-primary mx-auto w-100 sign-in-button mt-2">
	        Log in</button>
	        </div>	        
	    </form>
	    <p class="card-text mt-4 mb-5 text-center">
	    <a href="/?resetPassword" class="other-type-link">Forgot password?</a></p>
	    <p class="card-text text-center">Don't have an account? <a href="/?create">Sign up</a></p>
	</div>`;

module.exports = {
	logIn: logIn
};
