'use strict';

/**
 * HTML Markup
 *
 * @type {string}
 */

const signInElement = // Sign In element
	`<form id="form-%form%">
		<div class="form-group">
		  <div class="form-row align-items-end justify-content-around px-2 pt-2">
		    <div class="col-7 col-sm-9 col-md-8 col-lg-9">%detailElement%</div>
		    <div class="col-auto">%detailElement%</div>
		  </div>
		</div>
		<div class="identity-detail collapse" id="%accordionId%" data-parent="#identity-module">%details%</div>
	</form>`;

const signInInputElement =
	`<label for="%label-name%-box" class="text-uppercase">%label%</label>
		      <input type="%formType%" class="form-control-plaintext form-control mb-2" 
		      	readonly id="%formId%-box" value="%formValue%">`;

const buttonElement =
	`<button type="button" class="get-detail rest-request btn btn-secondary mb-2 %style%" 
		      	data-toggle="collapse" data-target="#%accordionId-target%" aria-expanded="true" 
		      		aria-controls="%accordionId-controls%">%button%</button>`;

const detailElement = // Form group element
	`<div class="form-group detail-element-box">
		<div class="form-row align-items-end justify-content-around pt-3 px-1">
			<div class="col-7 col-sm-8 col-md-7 col-lg-8">%detailElement%</div>
			<div class="col-auto position-relative">%detailElement%</div>
		</div>
	</div>`;

const passwordEyeElement =
	`<div class="input-group password-eye">
		%passwordEye%
			</div>`;

const passwordEyeButton =
	`<div class="input-group-append">
      <div class="input-group-text">
      	<i class="fas fa-eye reveal-password"></i>
      	<i class="fas fa-eye-slash cloak-password d-none"></i>
      </div>
    </div>`;

const detailLabelElement = // Form <label> element
	`<label for="%label-name%-box" 
			class="text-uppercase">%label%</label>`;

const detailInputElement = // Form <input> element
	`<input type="%formType%" class="form-control" 
			id="%formId%-box" value="%formValueOne%">%eyeball%
	 <div class="invalid-feedback this">
	 <strong>Shmoop</strong> <span>Fresh</span></div>`;

const detailCancelButton = // Cancel button
	`<button type="button" 
		class="btn btn-secondary  btn-adjustment cancel-btn" 
			data-toggle="collapse" data-target="#%cancel-id-target%" aria-controls="%cancel-id%">Cancel</button>`;

const detailChangeButton = // Change button
	`<button type="submit" 
			class="btn btn-secondary btn-adjustment change-btn">Change</button>`;

const detailMessage = // Message element
	`<small id="%helpBlockId%HelpBlock" class="form-text text-muted detailMessage">
			%detailMessage%</small>`;

const dividedElement = // Divider element
	`<div class="stripe-line text-center
		w-75 mx-auto my-4"> or </div>`;

const signInElementTitle = // Title element
	`<h2 class="mb-4 mt-4 ml-4">
		Sign-in Details</h2>`;

const spinner =
	`<i class="fas fa-spinner fa-spin
 			spinning-spinner d-none"></i>`;

module.exports = {
	spinner: spinner,
	passwordEyeButton: passwordEyeButton,
	passwordEyeElement: passwordEyeElement,
	buttonElement: buttonElement,
	signInInputElement: signInInputElement,
	signInElementTitle: signInElementTitle,
	dividedElement: dividedElement,
	signInElement: signInElement,
	detailElement: detailElement,
	detailLabelElement: detailLabelElement,
	detailInputElement: detailInputElement,
	detailCancelButton: detailCancelButton,
	detailChangeButton: detailChangeButton,
	detailMessage: detailMessage,
	detailElementX: detailElement
};
