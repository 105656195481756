// js-lint:disable dot-notation

'use strict';

const factory = require('./_createAccount-factory');
const common = require('../common/_index');
const message = require('../message/_messagePage');

/**
 * Process requested database information
 *
 * @param data -> form data
 * @param formId -> form ID
 * @param fresh
 * @returns {Promise<unknown>}
 */
const postData = (data, formId, fresh = true) => {
	const travel = fresh ? fresh : false;
	let getThis = common.Connect.postAjaxCall(data, formId, travel); // Ajax call to Identity API

	return new Promise((resolve, reject) => {
		getThis.then(response => resolve(response)).catch(err => reject(err));
	});
};

/**
 * Module listeners
 */
const pageListeners = () => {
	let listening = {
		eventValue: '',
		targetIdValue: '',

		set targetId(data) {
			this.targetIdValue = data;
		},
		get targetId() {
			return this.targetIdValue;
		},
		set eventType(data) {
			this.eventValue = data;
		},
		get eventType() {
			return this.eventValue;
		},

		// Initialize listening module
		init() {
			this.cacheDom();
			this.bindEvents();
		},

		// Cache Dom elements
		cacheDom() {
			this.page = document;
			this.submitButton = this.page.querySelector('.sign-up-button');
			this.revealPassword = this.page.querySelector('.reveal-password');
			this.cloakPassword = this.page.querySelector('.cloak-password');
			this.passwordId = this.page.getElementById('password');
			this.identitySignIn = this.page.getElementById('identity-create-account');
		},

		// Bind eventTypeData listeners
		bindEvents() {
			this.revealPassword.addEventListener('click', this.reveal.bind(this));
			this.cloakPassword.addEventListener('click', this.cloak.bind(this));
			this.identitySignIn.addEventListener('submit', this.initResponse.bind(this));
		},

		// Unbind eventTypeData listeners
		unBindEvents() {
			this.revealPassword.removeEventListener('click', this.reveal.bind(this));
			this.cloakPassword.removeEventListener('click', this.cloak.bind(this));
			this.identitySignIn.removeEventListener('submit', this.initResponse.bind(this));
			return this;
		},

		// Form submit functions
		redirectPage(data) {
			setTimeout(function() {
				window.location.assign(data);
			}, 3000);
		},

		// Controller
		initResponse(e) {
			// Set eventTypeData type to the module
			this.eventType = e.type;

			// Set target ID
			this.targetId = e.target.id;

			// Prevent form submission
			e.preventDefault();

			this.elementOff()
				.sendForm()
				.then(response => {
					console.dir(response);
					if (!response.userData.result) {
						console.log(response.userData.result);
						message.init.requestedPage = 'errorCreate';
						this.unBindEvents()
							.generatePage(message.init.requestedPage.htmlMarkup);
					} else {
						this.redirectPage('/?logIn');
					}
				})
				.catch(() => {
					message.init.requestedPage = 'errorMessage';
					this.unBindEvents()
						.generatePage(message.init.requestedPage.htmlMarkup);
				});
		},

		// Turn off status of elements
		elementOff() {
			// Set submit button
			const theButton = this.submitButton;

			// Current spinner from FontAwesome
			const spinner = theButton.previousElementSibling;

			// Change element states
			theButton.disabled = true;
			theButton.innerText = 'Confirming';

			// Change element styles
			theButton.classList.add('button-off');
			spinner.classList.remove('d-none');
			return this;
		},

		// Generate new page element
		generatePage(page) {
			document.querySelector('.main-content').innerHTML = page;
		},


		formToData(element) {
			let formData = {};

			[...element].forEach(item => {
				if (item.type !== 'submit') {
					formData[item.id] = item.value;
				}
			});
			return formData;
		},

		// Submit form
		sendForm() {
			const data = this.formToData(this.identitySignIn.elements);

			return new Promise((resolve, reject) => {
				postData(data, this.targetId)
					.then(response => {
						resolve(response);
					})
					.catch(err => {
						console.log(err);
						reject(err);
					});
			});
		},

		// Start up cookies
		initCookie() {
			common.Cookie.setCookie();
			common.Cookie.readCookie();
			return this;
		},

		// Allows view of the password
		reveal() {
			this.cloakPassword.classList.remove('d-none');
			this.revealPassword.classList.add('d-none');
			this.passwordId.type = 'text';
			this.passwordId.focus();
		},

		// Cloaks the password
		cloak() {
			this.revealPassword.classList.remove('d-none');
			this.cloakPassword.classList.add('d-none');
			this.passwordId.type = 'password';
			this.passwordId.focus();
		}
	};

	listening.init();
};

/**
 * Assembles the requested module elements and listeners
 *
 * Return requested module elements and listeners
 *
 * @returns {{htmlMarkup: *, pageListeners: *}} -> Requested module elements and listeners
 */
const init = {
	get requestedPage() {
		return {
			htmlMarkup: factory.init(),
			pageController: [pageListeners]
		};
	}
};

module.exports = {
	generateElement: factory,
	pageListeners: pageListeners,
	init: init
};
